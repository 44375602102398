.h2, h2 {
    font-size: 10px;
}

.section-icons{
   justify-content: space-around;
}

.section-icons__item{
    width: 30vw;
    max-width: 200px;
    cursor: pointer;
}

.img_paper{
    width: 100%;
    font-size: 100px;
    border-radius: 20px;
    padding: 20px;
}

#SlideIcons{
 transform-origin: 0 0 0 ;
}

#PaperIcon{
  background: #00408E;
}

#tituloSecIcon{
   padding-right: 75px;
   text-align: center;
   padding: 5px;
   font-size: 17px;
   margin-top: 5px;
   opacity:0.9

}

#buttonPromociones{
    background: #00408E;
}
#buttonPromociones2{
    background: #00408E;
}
#buttonPromociones3{
    background: #00408E;
}


