.App_Body{
    padding-bottom: 60px;
    padding-top: 40px;
}

@media (min-width:750px ){
    .App_Body {
        min-height: 100vh;
        max-width: 75vw;        
        margin: 0 25%;
    };
    
}


  