
#divMonto{
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 15px;
    margin-top: 0px;
}
#containerSlider {
    
    background-color: #d9dee7a8;   
    width: 100%;
    height: 250px;
    justify-items: center;
}
#containerSlider h2{
    text-align: center;
    padding-top: 10px;
    font-size: 20px;
    color:black;
}

#sliderBar {
    width: 100%;     
    color: #00408E;   
    
}

#montoSlider h2{
    color: #00408E;
    font-size: 40px;
    font-weight: 800;
}
.css-vjwkcr {
    display: inline-grid;
    justify-items: center;
}
