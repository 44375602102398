#gridRse{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

#itemTituloPage{
  text-align: center;
  padding-top: 25px;
}

#h2{
   text-align: center;
    color: #00408E;
    font-size:23px;
}

#gridItemContenedor{
    padding:10px;
    min-height: 450px;
}

#paperRse{
    background:#00408E;
     width:100%;
}

#h2Paper{
    text-align: center;
    color: white;
    padding: 5px;
    
}

#gridItemPaper{
    padding:10px;
}

#imgPaper{
    width: 100%;
     height: auto;
}
