#gridQuienesSomos{
    display: flex;
    flex-direction: column;
}

#titulo{
    text-align: center;
    padding: 15px;
    color: #00408E;
   
}

#divParrafo{
   padding: 10px;
}
#girdTarjeta{
    padding: 10px;
}

#slideQs{
    transform-origin : 0 0 0;
}

#gridFooter{
    padding-bottom: 5px;
}
#textMessage{
    min-height: 80px;
    
}

@media (min-width:750px ){
    #QuienesSomos_Tarjeta{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}