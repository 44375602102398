.Loading_Box{
    background-color: rgba(26, 24, 24, 0.153);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.Loading_Box_Img{
    position: absolute;
    left: 2px;
    top: 2px;
    
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.068);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border-left-color: rgb(1, 33, 54);
    position: absolute;
    top: 0;
    left: 0;
   
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  } 
  
  @media(min-width:750px ){
    .Loading_Box .Loading_Center_spiner{
        position: absolute ;
        width: auto;
        top: 30vh;
        left: 45vw;
    }
    }   

.Loading_Center_spiner {
        position:absolute ;
        top:230px;
        left:100px;
        background: fixed;
        z-index: 9999999999999999;
    }

