.Sucursales{
    margin-top: 30px;
    
}
#h3GrillaSucursales{
    color: #00408E;
    text-decoration: underline;
}
.Sucusales_titulo{
    text-align: center;
    color: #00408E;
   
}
.Sucusales_titulo H2{
    text-align: center;
    color: #00408E;
    font-size: 23PX;
}

.FormSucursales{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

#gridSucursalPage{
    text-align: center;
}
#pMap{
    padding-top: 5px;
    margin-bottom: auto;
}
#buttonMap{
   background-color :#faf9f90b;
}
#locationButton{
   color: #00408E;
}

#gridMapComponente{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 5px;
}