.imgLogoAppBar{
   
    min-height:20px;
  min-width:100px;
  height:auto;
  width:auto;
  max-height:20%;
  max-width:10%;
 padding: 10px;
}

#barNav{
  background: #00408E;
  width: 100%;
  position:"fixed"
  
}

@media (min-width:750px ){
  #barNav{
     max-height: 60px;
  }
}