.Form_Text h1{
   text-align: center;
   color: #00408E;
   font-weight: bold;
}
.Form_Text  p{
    text-align: center;
    color: #00408E;
    width: 58vw;
 }

 .Form_Buttom{
   display: flex;
   justify-content: space-around;
   flex-wrap: nowrap;
 }

 .Form_text_Field{
   margin: 10px;
   
 }

 .Grid_Form{
   padding-top: 30px;
   display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
 }

 .div_buttom_img{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 50vw;
  align-items: center;
  margin: 20px;
 }
 .Buttom_Img{
  color: #00408E;  
  font-size: 50px;
 }

 .Form_Text p{
  margin-left: 20vw;
  margin-right: 20vw;
 }

 /* SOLICITUD ERROR  */
 #SolicitudBadGrid{
   
   display: flex;
   justify-content: space-around;
   padding-top: 40px;
   flex-direction: column;
   align-items: center;
 }
 
 #ControlPointIcon{
  font-size: 80px;
  color: #00408E;
  margin: 10px;
  transform: rotate(135deg);
 }

 #ControlPointIconOK{
  font-size: 80px;
  color: #00408E;
  margin: 10px;
 }

#h2{
   text-align: center;
    color: #00408E;
}

#p_SolicitudBad{
  text-align: center;
   color: #00408E
}

#IconPhone{
  font-size: 30px;
}
#buttonCall{
  background-color: #00408E;
  margin: 10px;
  height: 50%;
}

#linkForm{
  text-align: center;
}

 @media (max-width:750px ){
  .Form_Text p{
    margin-left: 15vw;
    margin-right: 15vw;
   }
}
