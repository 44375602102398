
.rootDiv{
   position: relative;
   padding-top: 7px;
}

@media screen and (min-width: 1490px) {
    .rootDiv{
        
        padding-top: 10px;
    }
}

#imgCarrousel{
    min-height: 180px;
    max-height: 350px;
    padding-top: 15px;
}

.divButton{
    position: absolute;
    bottom: 15%;
    left: 41%;
    right: 40%;
}


   
    


