
.departamento_Sub{
    text-align: initial;
    color: #00408E;
       
}

@media screen and (min-width: 1490px) {
    #containerSuc{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.departamento_Sub h2{
    border-bottom:solid;
    
}
.icon_localitation{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: flex-end;
}

#PaperSucursales{
    border-top-style: solid;
    border-top-color: #00408E;
    padding: 5px;
    
}

#FmdGoodAut{
    color:#00408E;
    font-size: 30px;

}

#pDireccion{
    color:#00408E;
    align-self: center;
}

#buttonWpp{
    color: white;
    max-width: 150px;
}

#imgWpp{
  min-height:20px;
  max-height: 40px;
}