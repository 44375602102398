
@media (min-width:750px ){
    #buttom_footer_in{
        width: 50%;
        background-color: #00408E;
        color: white;
        border-color: white;
        margin: 10px;
        border-width: 3px;
    }
}
.buttomFooter{
    width: 100%;
    background: #00408E;
    text-align-last: center;
}

#buttom_footer_in{
    width: 50%;
    background-color: #00408E;
    color: white;
    border-color: white;
    margin: 10px;
    border-width: 3px;
}