#subtitlefootContent{
    font-size: 12px;
    font-weight: bold;
    color:'black';
        margin: 0;
        
}

.logoTextFott_SQLA{
    width: 50px;
}
.logoTextFott_LogoCredito{
    width: 120px;
    margin-left: 10px;
    padding-top: 8px;
}

#pfooterContent{
    font-size: 12px;
    color: 'black' ;
    margin: 0;
}

#logoTextFoot{
    background-color: #00408E;
}

#FooterList{
    background-color: #00408E;
    margin: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

#FooterList #gridList{
    min-width: max-content;
}

#FooterList #gridList dt{
    min-width: max-content;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
#FooterList dl{
   color: azure;
   font-size: small;  
}

#footIcon {
    display: flex;
    flex-direction: row;    
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    width: 60%;   
}

#footIcon #icons{
    display: flex;
    align-items: flex-end;
    width: 80%;
    justify-content: space-around;
}

#footIcon #icons{
    display: flex;
    align-items: flex-end;
    width: 80%;
}
@media (min-width:750px ){
    #footIcon #icons{
        display: flex;
        align-items: flex-end;
        width: 50%;
    }}

.icon_footer{
    color: white;
}