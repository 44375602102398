
#gridContainerNombreApellido{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#gridContainerDatosOtros{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#itemGridDatosOtros{
    padding: 15px;
}

#gridButtonForm{
    display: flex;
    justify-content: flex-end;
    padding: 15px;
}


#containerModal{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
}


#logoModal{
    color: green;
    font-size: 60px;
}

#itemModalTexto{
    color: #00408E;
    padding-top: 15px;
}
