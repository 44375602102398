#gridPromo{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#itemTituloPage{
    text-align: center;
    padding-top: 25px;
  }

  #h2{
    text-align: center;
     color: #00408E;
     font-size: 23px;
 }

 #gridItemContenedor{
    padding:10px;
    min-height: 450px;
}
#gridFooter{
    padding-bottom: 5px;
}