#listaDocGrid{
    display: flex;
    flex-direction: column;
    min-height: 450px;
}

#listaTitulo{
    align-self: center;
}
#listaTitulo h2{
    padding: 10px;
    color: #00408E;
    font-size:23px;
   
}
#lista_icon{
    color: #00408E;
}

#listaDocumento{
    justify-content: start;
    padding-top: 20px;
}
#lista_texto{
    color: #00408E;
}
