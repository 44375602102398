.divCardMV{
    padding: 5px;
}
#PaperCardMV { 
    background-color:#00408E;
     border-radius: 10px;
}

#item_Paper{
    text-align: center;
}
#item_Paper p{
    color: white;
    padding: 5px;
}

#PaperCardMV h1{
   color: white;
    font-size: 1.4em;
}

#div_Politica {
    text-align: center;
 }

 #listMV{
    color: white;
    padding: 10px;
 }